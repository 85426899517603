import { getHttpClient } from "@root/httpClient";
import { setDashboardData } from "@root/store/counter/dashboardDataSlice";
import { RootState } from "@root/store/store";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setUser,
  setWithdrawalConditions,
} from "@root/store/counter/userInfoSlice";
import { setDeductionReceipt } from "@root/store/counter/deductionReceiptDataSlice";
import { MD5 } from "crypto-js";
import { setBankDetail } from "@root/store/counter/bankDetailSlice";
import {
  setGetDashboardDataDone,
  setHasTimeRedux,
} from "@root/store/counter/appStatusSlice";
import { getHttpClientV2 } from "@root/httpClientV2";

export default function useDashboardData() {
  const dispatch = useDispatch();
  const [isGetDashboardDone, setIsGetDashBoardDone] = useState(false);
  const [hasTime, setHasTime] = useState(false);
  const [isGetWithdrawalConditionsDone, setGetWithdrawalConditionsDone] =
    useState(false);
  // const [isCreateDeductionDone, setCreateDeductionDone] = useState(false);
  const [isChangePasswordDone, setIsChangePasswordDone] = useState(false);
  const [isUpdateProfileDone, setIsUpdateProfileDone] = useState(false);
  const [isGetBankAccountDone, setIsGetBankAccountDone] = useState(false);

  // const payment_verification_code: any = useSelector(
  //   (state: RootState) => state.withdrawal.otpCode
  // );
  const user: any = useSelector((state: RootState) => state.userInfo.value);
  const dashboardData: any = useSelector(
    (state: RootState) => state.dashboardData.value
  );
  const WithdrawalConditionsData: any = useSelector(
    (state: RootState) => state.userInfo.withdrawalConditions
  );

  const getDashboardData = useCallback(
    async (staff_id: string, payCycle?: string | null) => {
      const httpClient = getHttpClient();
      const time_offset = "25200000";
      const accessToken: any = localStorage.getItem("token");
      if (accessToken) {
        try {
          const { data } = await httpClient.get<{
            result: {
              success: boolean;
              data: any;
            };
          }>("/api/user/get-dashboard-data", {
            params: {
              time_offset,
              staff_id,
              // accessToken,
              payCycle,
            },
          });

          if (!data?.result) {
            setHasTime(false);
            dispatch(setHasTimeRedux(false));
            setIsGetDashBoardDone(true);
            dispatch(setGetDashboardDataDone(true));
            return null;
          } else {
            setHasTime(true);
            dispatch(setHasTimeRedux(true));
            setIsGetDashBoardDone(true);
            dispatch(setGetDashboardDataDone(true));
            dispatch(setDashboardData(data?.result?.data));
            return data;
          }
        } catch (error: any) {
          console.log(error);
          setIsGetDashBoardDone(true);
          dispatch(setGetDashboardDataDone(true));
          dispatch(setHasTimeRedux(true));
          setHasTime(false);
          if (error.response) {
            return {
              success: false,
              message: error?.response?.data?.message,
            };
          } else {
            return {
              success: false,
              message: "Get dashboard data failed!",
            };
          }
        }
      }
    },
    [dispatch]
  );

  const getWithdrawalConditions = useCallback(
    async (staffId: string) => {
      const httpClient = getHttpClientV2();
      // const accessToken: any = localStorage.getItem("token");

      try {
        const { data } = await httpClient.get<{
          result: {
            type: boolean;
            data: any;
            statusCode: any;
            code: any;
          };
        }>("/api/user/get-withdrawal-conditions", {
          params: {
            staffId,
            // accessToken,
          },
        });
        dispatch(setWithdrawalConditions(data.result.data));
        setGetWithdrawalConditionsDone(false);
      } catch (error: any) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const createDeduction = async (amount: number, payPeriodId?: number) => {
    const httpClient = getHttpClient();
    // if (user?.result?.data?.companies?.is_verify_code_by_sms) {
    //   try {
    //     const { data } = await httpClient.post<{
    //       result: {
    //         success: boolean;
    //         response: any;
    //       };
    //     }>("/api/user/create-deduction", {
    //       amount: amount,
    //       company_id: user?.result?.data?.companies?._id,
    //       name: "Withdraw via CashD",
    //       pay_period_id: payPeriodId,
    //       resource_from: "SERVER",
    //       staff_id: dashboardData?.pay_calculates?.staff_id,
    //       time_offset: "25200000",
    //       transaction_fee_type: WithdrawalConditionsData?.transaction_fee_type,
    //       transaction_fee_value:
    //         WithdrawalConditionsData?.transaction_fee_value,
    //       type: 1,
    //       payment_verification_code: payment_verification_code
    //         ? payment_verification_code
    //         : "",
    //     });
    //     // setCreateDeductionDone(true);
    //     dispatch(setDeductionReceipt(data));
    //     return data;
    //   } catch (error: any) {
    //     if (error?.response) {
    //       return {
    //         code: error?.response?.data?.code,
    //         errorCode: error?.response?.data?.errorCode,
    //       };

    //     }
    //     console.log("ERROR", error);
    //     // setCreateDeductionDone(true);
    //   }
    // } else {
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/create-deduction", {
        amount: amount,
        company_id: user?.result?.data?.companies?._id,
        name: "Withdraw via CashD",
        pay_period_id: payPeriodId,
        resource_from: "SERVER",
        staff_id: dashboardData?.pay_calculates?.staff_id,
        time_offset: "25200000",
        transaction_fee_type: WithdrawalConditionsData?.transaction_fee_type,
        transaction_fee_value: WithdrawalConditionsData?.transaction_fee_value,
        type: 1,
      });
      // setCreateDeductionDone(true);
      dispatch(setDeductionReceipt(data));
      return data;
    } catch (error: any) {
      if (error?.response) {
        return {
          message: error?.response?.data?.message,
          code: error?.response?.data?.code,
        };
      }
      console.log("ERROR", error);
      // setCreateDeductionDone(true);
    }
    // }
  };

  const getAvartar = async () => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.get<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/get-avatar", {
        params: {
          imagePath: user?.avatar_path,
        },
      });
      return data;
    } catch (error: any) {
      console.log("ERROR", error);
    }
  };

  const changePassword = async (
    current_password: string,
    new_password: string
  ) => {
    const httpClient = getHttpClient();
    const device_id = localStorage.getItem("device_id");
    setIsChangePasswordDone(true);
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/change-password", {
        current_password: MD5(current_password).toString(),
        new_password: MD5(new_password).toString(),
        device_id: device_id,
      });
      setIsChangePasswordDone(false);
      return data;
    } catch (error: any) {
      console.log("ERROR", error);
      if (
        error?.response?.data?.response === "Current password is incorrect."
      ) {
        setIsChangePasswordDone(false);
        return {
          success: false,
          message: error?.response?.data?.response,
        };
      }
    }
  };

  const getCountry = useCallback(async () => {
    let countriesData: any;
    let countriesDataFinal: any;

    const httpClient = getHttpClient();

    try {
      const { data } = await httpClient.get<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/get-countries");
      countriesData = data?.result;
      countriesDataFinal = countriesData?.result;
      return countriesDataFinal;
    } catch (error: any) {
      console.log("ERROR", error);
    }
  }, []);

  const getState = useCallback(async (countryId: any) => {
    let statesData: any;
    let statesDataFinal: any;

    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.get<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/get-state", {
        params: {
          countryId: countryId,
        },
      });
      statesData = data?.result;
      statesDataFinal = statesData?.result;
      return statesDataFinal;
    } catch (error: any) {
      console.log("ERROR", error);
    }
  }, []);

  const updateProfile = async (
    staff_id: string,
    first_name: string,
    last_name: string,
    mobile: string,
    mobile_country_code: string,
    address_line_1: string,
    address_line_2: string,
    address_line_3: string,
    address_country_id: any,
    address_line_state_id: string,
    postcode: string,
    address_line_city: string
    // suburb: string
  ) => {
    const httpClient = getHttpClient();
    let dataFinal: any;
    setIsUpdateProfileDone(true);
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/update-profile-user", {
        staff_id,
        first_name,
        last_name,
        mobile,
        mobile_country_code,
        address_line_1,
        address_line_2,
        address_line_3,
        address_country_id,
        address_line_state_id,
        postcode,
        address_line_city,
        // suburb,
      });
      setIsUpdateProfileDone(false);
      dataFinal = data;
      dispatch(setUser(dataFinal?.result?.result));
      return dataFinal;
    } catch (error: any) {
      console.log("ERROR", error);
      setIsUpdateProfileDone(false);
      return {
        success: false,
        message: error?.response?.data?.response,
      };
    }
  };

  const getBankAccount = async (staff_id: string) => {
    const httpClient = getHttpClient();
    setIsUpdateProfileDone(true);
    try {
      const { data } = await httpClient.get<{
        result: {
          type: string;
          data: any;
        };
      }>(
        `/api/user/get-bank-account?staffId=${staff_id}`
      );

      dispatch(setBankDetail(data?.result?.data));
      setIsGetBankAccountDone(true);

      return data?.result;
    } catch (error: any) {
      console.log("ERROR", error);
      setIsGetBankAccountDone(true);

      return {
        success: false,
        message: error?.response?.data?.result?.message,
      };
    }
  };

  const changeBankAccount = async (
    staff_id: string,
    bank_id: string,
    // encryptionKey: string
  ) => {
    const httpClient = getHttpClient();

    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/change-bank-account", {
        staff_id,
        bank_id,
        // encryptionKey,
      });
      return data;
    } catch (error: any) {
      console.log("ERROR", error);
      return {
        success: false,
        message: error?.response?.data?.response,
      };
    }
  };

  const getSuburbs = useCallback(async (countryId: any, stateId: any) => {
    let sububsData: any;
    let sububsDataFinal: any;

    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.get<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/get-suburbs", {
        params: {
          countryId: countryId,
          stateId: stateId,
        },
      });
      sububsData = data?.result;
      sububsDataFinal = sububsData?.result;
      return sububsDataFinal;
    } catch (error: any) {
      console.log("ERROR", error);
    }
  }, []);

  const addNewBankAccount = async (
    staff_id: string,
    bank_name: string,
    bank_account_name: string,
    bank_bsb_number: string,
    bank_account_number: string
  ) => {
    const httpClient = getHttpClient();
    let dataSecond: any;
    let UserSecond = { ...user };
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/add-new-bank-account", {
        staff_id,
        bank_name,
        bank_account_name,
        bank_bsb_number,
        bank_account_number,
      });
      dataSecond = data;
      UserSecond.staff_infor = {
        ...UserSecond.staff_infor,
        bank_account_name: dataSecond?.result?.result?.bank_account_name,
        bank_bsb_number: dataSecond?.result?.result?.bank_bsb_number,
        bank_account_number: dataSecond?.result?.result?.bank_account_number,
        bank_name: dataSecond?.result?.result?.bank_name,
      };
      dispatch(setUser(UserSecond));
      return dataSecond;
    } catch (error: any) {
      console.log("ERROR", error);
      return {
        success: false,
        message: error?.response?.data?.response,
      };
    }
  };

  const verifyBankAccount = useCallback(async (staff_id: string) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/verify-bank-account", {
        staff_id,
      });
      return data;
    } catch (error: any) {
      console.log("ERROR", error);
      return {
        success: false,
        message: error?.response?.data?.response,
      };
    }
  }, []);

  const submitValidateBankAccount = async (
    staff_id: string,
    validate_transaction_first_payment: string,
    validate_transaction_second_payment: string
  ) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/validate-bank-account", {
        staff_id,
        validate_transaction_first_payment,
        validate_transaction_second_payment,
      });
      return data;
    } catch (error: any) {
      console.log("ERROR", error);
      return {
        success: false,
        message: error?.response?.data?.response,
      };
    }
  };

  return {
    getDashboardData,
    isGetDashboardDone,
    setIsGetDashBoardDone,
    hasTime,
    setHasTime,
    getWithdrawalConditions,
    user,
    setGetWithdrawalConditionsDone,
    isGetWithdrawalConditionsDone,
    createDeduction,
    getAvartar,
    changePassword,
    isChangePasswordDone,
    setIsChangePasswordDone,
    getCountry,
    getState,
    updateProfile,
    isUpdateProfileDone,
    getBankAccount,
    changeBankAccount,
    isGetBankAccountDone,
    getSuburbs,
    addNewBankAccount,
    verifyBankAccount,
    submitValidateBankAccount,
  };
}
